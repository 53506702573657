import { createContext, useContext } from 'react';

const DrawerContext = createContext<
  ((title: string, content: React.ReactNode) => void) | null
>(null);

export const DrawerProvider = DrawerContext.Provider;

export function useDrawer() {
  const ctx = useContext(DrawerContext);
  if (!ctx) {
    throw new Error('Missing DrawerProvider');
  }
  return ctx;
}
