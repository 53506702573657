import React, { useCallback, useEffect, useRef, useState } from 'react';
// @mui
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useInbox } from '@trycourier/react-hooks';
// utils
import { usePageVisible } from 'utils/usePageVisible';
import { fToNow } from '../../../utils/formatTime';
// components
import * as Sentry from '@sentry/browser';
import { Icon, Text } from '@subframe/core';
import { CourierProvider, IActionElemental } from '@trycourier/react-provider';
import {
  AtomicTooltip,
  Divider,
  WrapWithBadge,
} from 'components/design-system';
import { Stack } from 'components/utils';
import { toastAutoHideDuration } from 'constants/toasts';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import { Badge, Button, IconButton } from 'subframe/index';
import MenuPopover from '../../../components/MenuPopover';

// ----------------------------------------------------------------------

export default function NotificationsPopover(): JSX.Element {
  const { user } = useUserAccountState();
  if (import.meta.env.VITE_COURIER_CLIENT_KEY) {
    return (
      <CourierProvider
        clientKey={import.meta.env.VITE_COURIER_CLIENT_KEY}
        userId={user?.email}
      >
        <NotificationPopoverImplementation />
      </CourierProvider>
    );
  } else {
    return <></>;
  }
}

// ----------------------------------------------------------------------

function NotificationPopoverImplementation(): JSX.Element {
  const {
    unreadMessageCount = 0,
    getUnreadMessageCount,
    fetchMessages,
    messages = [],
    setView,
    lastMessagesFetched,
    markAllAsRead,
    startCursor,
    markMessageRead,
    isLoading,
  } = useInbox();

  const [open, setOpen] = useState<HTMLElement | null>(null);
  const notificationListRef = useRef<any>(null);
  const { organizations } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();

  const isUserMemberOfOrganization = (orgId: string) => {
    for (let i = 0; i < organizations?.length; i++) {
      if (organizations[i]?.id === orgId) {
        return true;
      }
    }
    return false;
  };

  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  }, []);

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    const now = new Date().getTime();
    const dateDiff = lastMessagesFetched
      ? now - lastMessagesFetched
      : undefined;
    setView('messages');
    if (!dateDiff || dateDiff > 3600000) {
      fetchMessages();
      getUnreadMessageCount();
    }
  }, []);

  useEffect(() => {
    // This starts fetching all the notifications from courier after first pagination
    // request completes. All other calls are further sent out with a 100 ms delay for
    // being graceful to courier's API.
    if (startCursor) {
      const timeOutSub = setTimeout(() => {
        fetchMessages({
          after: startCursor,
        });
        clearTimeout(timeOutSub);
      }, 100);
    }
  }, [startCursor]);

  function markAllNotificationsRead() {
    markAllAsRead();
  }

  usePageVisible((isVisible) => {
    if (!isVisible) {
      return;
    }
    if (!isLoading) {
      getUnreadMessageCount();
    }
  });

  return (
    <>
      <AtomicTooltip tooltipContent={'Notifications'}>
        <Stack direction="row" alignItems="start">
          <WrapWithBadge
            badge={
              unreadMessageCount ? (
                <Badge
                  style={{
                    marginBottom: '-10px',
                    marginLeft: '-5px',
                  }}
                >
                  {unreadMessageCount}
                </Badge>
              ) : (
                <></>
              )
            }
          >
            <IconButton
              id="notification-bell"
              icon="FeatherBell"
              onClick={handleOpen}
              size="medium"
            />
          </WrapWithBadge>
        </Stack>
      </AtomicTooltip>
      {/* TODO: replace MUI based MenuPopover with subframe */}
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
        id="notification-menu"
      >
        <Stack direction="row" spacing="fill" className="p-4">
          <Stack>
            <Text variant="body">Notifications</Text>
            <Text variant="body" color="subtext">
              You have {unreadMessageCount} unread messages
            </Text>
          </Stack>

          {unreadMessageCount > 0 && (
            <AtomicTooltip tooltipContent=" Mark all as read">
              <IconButton
                variant="brand-tertiary"
                onClick={markAllNotificationsRead}
                icon="FeatherCheckCheck"
                className="w-[30px] h-[30px]"
              />
            </AtomicTooltip>
          )}
        </Stack>

        <Divider />
        {messages?.length ? (
          // TODO: replace MUI List with subframe
          <List
            sx={{
              height: { xs: 340, sm: 500 },
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                backgroundColor: 'transparent',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '20px',
                border: '4px solid transparent',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                backgroundClip: 'content-box',
              },
            }}
            disablePadding
            ref={notificationListRef}
          >
            {messages.map((message: any) => (
              <NotificationItem
                key={message.created}
                itemKey={message.messageId}
                notification={{
                  id: message.messageId,
                  title: message.title,
                  description: message.preview,
                  createdAt: new Date(Date.parse(message.created)),
                  action: message?.actions?.length
                    ? message?.actions[0]
                    : undefined,
                  isUnRead: !message.read,
                  onMarkReadClick: async (
                    event: React.MouseEvent<HTMLElement>,
                  ) => {
                    if (
                      !(
                        event?.target instanceof HTMLElement &&
                        event?.target?.id === 'action-button'
                      )
                    ) {
                      await markMessageRead(message.messageId);
                    }
                  },
                  onClick: async () => {
                    if (!message.read) {
                      await markMessageRead(message.messageId);
                    }
                    const messageAction = message?.actions?.length
                      ? message?.actions[0]
                      : undefined;

                    if (messageAction?.href) {
                      if (messageAction?.content === 'Accept Invite') {
                        window.open(messageAction?.href, '_self');
                      } else {
                        const notificationOrgId =
                          messageAction.href.split('/')[4];
                        if (isUserMemberOfOrganization(notificationOrgId)) {
                          window.open(messageAction?.href, '_self');
                        } else {
                          const errMsg = `Access Denied. You are not part of this team. Please contact your team admin to get invited`;
                          enqueueSnackbar(errMsg, {
                            variant: 'error',
                            autoHideDuration: toastAutoHideDuration,
                          });
                          Sentry.captureException(errMsg, { level: 'log' });
                        }
                      }
                    }
                  },
                }}
              />
            ))}
          </List>
        ) : (
          <div
            style={{
              width: '100%',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Text
              variant="body"
              style={{
                margin: '16px',
                textAlign: 'center',
              }}
              color="subtext"
            >
              No notifications
            </Text>
          </div>
        )}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

type NotificationItemProps = {
  id: string;
  title: string;
  description: string | null;
  action: IActionElemental | undefined;
  createdAt: Date;
  isUnRead: boolean;
  onMarkReadClick: (event: React.MouseEvent<HTMLElement>) => void;
  onClick: () => void;
};

function NotificationItem({
  itemKey,
  notification,
}: {
  itemKey: string;
  notification: NotificationItemProps;
}) {
  const { lastLoginTime } = useUserAccountState();

  const isButtonDisabled =
    // If the notification is an invite and the invite came after the last login time
    notification.title.includes('Invite') &&
    notification.action !== undefined &&
    notification.createdAt.getTime() / 1000 < lastLoginTime;

  if (isButtonDisabled && notification.isUnRead) {
    // If the invite is no longer active, automatically mark it as read
    notification.onMarkReadClick({} as React.MouseEvent<HTMLElement>);
  }

  const isNotifNewTeamInvite = notification?.title.includes('New Team Invite');

  return (
    // TODO: replace MUI ListItem with subframe
    <ListItem
      key={itemKey}
      disablePadding
      onClick={notification.isUnRead ? notification.onMarkReadClick : undefined}
      secondaryAction={
        notification.isUnRead && (
          <AtomicTooltip tooltipContent="Mark as read">
            <IconButton
              variant="brand-tertiary"
              icon="FeatherCheck"
              className="w-[30px] h-[30px]"
            />
          </AtomicTooltip>
        )
      }
    >
      <ListItemButton
        sx={{
          py: 1.5,
          px: 2.5,
          mt: '1px',
          ...(notification.isUnRead && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <ListItemText
          primary={
            <span>
              <Text
                variant="body"
                style={{
                  overflowWrap: 'break-word',
                  float: 'left',
                  marginRight: '5px',
                  lineHeight: '24px',
                }}
              >
                {notification.title}
              </Text>
              {notification?.description && (
                <Text
                  variant="body"
                  style={{
                    overflowWrap: 'break-word',
                    clear: 'left',
                    lineHeight: '24px',
                  }}
                  color="subtext"
                  className={`${isNotifNewTeamInvite ? 'ph-no-capture' : ''} `}
                >
                  {notification.description}
                </Text>
              )}
            </span>
          }
          secondary={
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              className="justify-between mt-[5px] w-[300px]"
            >
              <Stack>
                {notification.action?.href && (
                  <AtomicTooltip
                    tooltipContent={
                      isButtonDisabled
                        ? 'This invite is no longer active'
                        : `Click here to ${
                            notification.action?.content || 'view details'
                          }`
                    }
                  >
                    <span>
                      <Button
                        id="action-button"
                        size="medium"
                        variant="neutral-secondary"
                        onClick={notification.onClick}
                        disabled={isButtonDisabled}
                      >
                        {notification.action?.content}
                      </Button>
                    </span>
                  </AtomicTooltip>
                )}
              </Stack>

              <Stack direction="row">
                <Text
                  variant="label"
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  color="subtext"
                >
                  <Icon name="FeatherClock3" style={{ marginRight: '4px' }} />
                  {fToNow(notification.createdAt)}
                </Text>
              </Stack>
            </Stack>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}
