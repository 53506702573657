// @mui
import { styled, useTheme, Theme } from '@mui/material/styles';
import { Box } from '@mui/material';

// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER } from '../../../config';
import { MenuBreadCrumbsHeader } from 'components/BreadCrumbsHeaders';
import { Text } from '@subframe/core';

type FormValues = {
  severity: string;
};

type Props = {
  isCollapse?: boolean;
  verticalLayout?: boolean;
  title: string;
  AlignmentToggleButton?: JSX.Element | null;
  SearchBar?: JSX.Element | null;
  FilterButton?: JSX.Element | null;
  actionButton?: JSX.Element | null;
  handleFilterSubmit?: (formValues: FormValues) => void;
  filterClustersType?: FormValues;
  styleType?: string;
};

type SharedProps = {
  theme: Theme;
};

const sharedStyles = ({ theme }: SharedProps) => ({
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_SECTION_HEIGHT,
  },
});

const RootStyle = styled(Box)(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  ...sharedStyles({ theme }),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  position: 'sticky',
}));

const RootFlatStyle = styled(Box)(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  ...sharedStyles({ theme }),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
}));

const RootNoStyle = styled(Box)(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  ...sharedStyles({ theme }),
}));

// ---------------------------------------

export default function SectionHeader({
  styleType,
  title,
  SearchBar,
  AlignmentToggleButton,
  FilterButton,
  actionButton,
}: Props) {
  const theme = useTheme();

  let RootStyledComponent;

  switch (styleType) {
    case 'sticky':
      RootStyledComponent = RootStyle;
      break;

    case 'flat':
      RootStyledComponent = RootFlatStyle;
      break;

    case 'none':
      RootStyledComponent = RootNoStyle;
      break;

    default:
      RootStyledComponent = RootStyle;
  }

  return (
    <RootStyledComponent>
      <Box
        sx={{
          borderBottom:
            styleType === 'none' ? '' : `1px solid ${theme.palette.grey[600]}`,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 2,
          paddingTop: '32px',
        }}
      >
        {title === 'Clusters' || title == 'Fleet' ? (
          <MenuBreadCrumbsHeader
            pageTitle={title}
            menuSubHeader="Risk Ledger"
          ></MenuBreadCrumbsHeader>
        ) : (
          <Text id="section-header-title" variant="subheader">
            {title}
          </Text>
        )}

        <Box sx={{ display: 'flex' }}>
          {!!SearchBar && SearchBar}
          {!!AlignmentToggleButton && AlignmentToggleButton}
          {!!FilterButton && FilterButton}
          {!!actionButton && actionButton}
        </Box>
      </Box>
    </RootStyledComponent>
  );
}
