import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import useAccountIdRoute from 'src/hooks/useAccountIdRoute';
import LoadingScreen from 'components/LoadingScreen';
import useUserAccountState from 'hooks/useUserAccountState';
import { Drawer } from './drawer/component';
import { DrawerProvider } from './drawer/context';

// ----------------------------------------------------------------------

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  minHeight: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const location = useLocation();
  const { account, currentOrganization } = useUserAccountState();
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const { themeLayout } = useSettings();
  const isDesktop = useResponsive('up', 'lg');

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const verticalLayout = themeLayout === 'vertical';

  const getPathEndName = (path: string) => {
    const splittedPaths = path.split('/');
    return splittedPaths[splittedPaths.length - 1]
      ? splittedPaths[splittedPaths.length - 1]
      : splittedPaths[splittedPaths.length - 2];
  };

  const newPath = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/${getPathEndName(
      window.location.pathname,
    )}`,
  );

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [drawerState, setDrawerState] = useState<{
    open: boolean;
    title: string;
    contents: React.ReactNode;
  }>({
    open: false,
    title: '',
    contents: '',
  });
  const updateDrawer = (title: string, contents: React.ReactNode) => {
    setDrawerState({ open: true, title: title, contents: contents });
  };
  useEffect(() => {
    setDrawerState({ open: false, title: '', contents: '' });
  }, [location]);

  useEffect(() => {
    if (
      (pathname.endsWith('/orgs') || pathname.endsWith('/orgs/')) &&
      newPath !== ''
    ) {
      if (getPathEndName(window.location.pathname) === 'orgs') {
        navigate(
          `/orgs/${currentOrganization?.id}/accounts/${account?.accountId}/clusters`,
        );
      } else {
        navigate(`${window.location.pathname}${window.location.search}`);
      }
    } else if (
      pathname.endsWith('/troubleshoot-k8s-connector') &&
      newPath !== ''
    ) {
      navigate(
        `/orgs/${currentOrganization?.id}/accounts/${account?.accountId}/troubleshoot-k8s-connector`,
      );
    }
  }, [
    pathname,
    newPath,
    navigate,
    account?.accountId,
    currentOrganization?.id,
  ]);

  if (verticalLayout) {
    return (
      <DrawerProvider value={updateDrawer}>
        <>
          <DashboardHeader
            onOpenSidebar={() => setSidebarOpen(true)}
            verticalLayout={verticalLayout}
          />

          {isDesktop ? (
            <NavbarHorizontal />
          ) : (
            <NavbarVertical
              isOpenSidebar={sidebarOpen}
              onCloseSidebar={() => setSidebarOpen(false)}
            />
          )}

          <Box
            component="main"
            sx={{
              px: { lg: 2 },
              pt: {
                xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
                lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
              },
              pb: {
                xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
                lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
              },
            }}
          >
            <Outlet />
          </Box>
        </>
      </DrawerProvider>
    );
  }

  return (
    <DrawerProvider value={updateDrawer}>
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {account?.token ? (
          <>
            <DashboardHeader
              isCollapse={isCollapse}
              onOpenSidebar={() => setSidebarOpen(true)}
            />
            <NavbarVertical
              isOpenSidebar={sidebarOpen}
              onCloseSidebar={() => setSidebarOpen(false)}
            />
            <MainStyle collapseClick={collapseClick}>
              <Outlet />
            </MainStyle>
            <Drawer
              title={drawerState.title}
              open={drawerState.open}
              setOpen={(open) =>
                setDrawerState((prior) => ({ ...prior, open }))
              }
            >
              {drawerState.contents}
            </Drawer>
          </>
        ) : (
          <LoadingScreen />
        )}
      </Box>
    </DrawerProvider>
  );
}
