// @mui
import { Box } from '@mui/material';
import Iconify from 'components/Iconify';
// components
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

type Props = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

export default function CollapseButton({
  onToggleCollapse,
  collapseClick,
}: Props) {
  return (
    <IconButtonAnimate onClick={onToggleCollapse}>
      <Box
        sx={{
          lineHeight: 0,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(collapseClick && {
            transform: 'rotate(180deg)',
          }),
        }}
      >
        <Iconify icon={'bx:arrow-from-right'} />
      </Box>
    </IconButtonAnimate>
  );
}
