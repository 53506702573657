import { NavListProps } from 'components/nav-section/type';
import Iconify from 'src/components/Iconify';
import { useGate } from 'statsig-react';

export default function NavConfig(): {
  subheader: string;
  items: NavListProps[];
}[] {
  const flippedArsigViewSidenavTab = useGate('flipped_arsig_view_sidenav_tab');
  const allowAddonUpgradePlans = useGate('allow_addon_upgrade_plans');

  const navConfigItems: NavListProps[] = [];
  if (flippedArsigViewSidenavTab.value) {
    navConfigItems.push({
      id: 'availabilityrisks-sidenav-tab',
      title: 'Fleet',
      path: '/orgs/:orgId/accounts/:accountId/availability-risks',
      icon: <Iconify icon={'material-symbols:warning-rounded'} />,
      trackerEvent: 'availabilityrisks-sidenav',
    });
  }
  navConfigItems.push({
    id: 'clusters-sidenav-tab',
    title: 'Clusters',
    path: '/orgs/:orgId/accounts/:accountId/clusters',
    icon: <Iconify icon={'ph:aperture'} />,
    trackerEvent: 'clusters-sidenav',
  });

  // Artifact Register items
  const artifactRegisterNavItems = [];

  artifactRegisterNavItems.push(
    {
      id: 'ar-controlplane-sidenav-tab',
      title: 'Control Plane',
      path: '/orgs/:orgId/accounts/:accountId/artifact_register/control_plane',
      icon: <Iconify icon={'ph:aperture'} />,
      trackerEvent: 'infra-clusters-sidenav',
    },
    {
      id: 'vr-addons-sidenav-tab',
      title: 'Add-ons',
      path: '/orgs/:orgId/accounts/:accountId/artifact_register/addons',
      icon: <Iconify icon={'iconoir:3d-select-solid'} />,
      trackerEvent: 'infra-addons',
    },
  );

  // Upgrades section
  const upgradesNavItems = [];

  upgradesNavItems.push({
    id: 'upgrades-clusters-sidenav-tab',
    title: 'Cluster Upgrades',
    path: '/orgs/:orgId/accounts/:accountId/upgrades/clusters',
    icon: <Iconify icon={'ph:aperture'} />,
    trackerEvent: 'upgrades-clusters',
  });

  if (allowAddonUpgradePlans.value) {
    upgradesNavItems.push({
      id: 'upgrades-addons-sidenav-tab',
      title: 'Add-on Upgrades',
      path: '/orgs/:orgId/accounts/:accountId/upgrades/addons',
      icon: <Iconify icon={'iconoir:3d-select-solid'} />,
      trackerEvent: 'upgrades-addons',
    });
  }
  upgradesNavItems.push({
    id: 'upgrades-templates-sidenav-tab',
    title: 'Upgrade Templates',
    path: '/orgs/:orgId/accounts/:accountId/upgrades/templates',
    icon: <Iconify icon={'ep:document'} />,
    trackerEvent: 'upgrades-templates',
  });

  //Collaboarate and customize items
  const configureNavItems = [];
  configureNavItems.push({
    id: 'teams-sidenav-tab',
    title: 'Teams',
    path: '/orgs/:orgId/accounts/:accountId/teams',
    icon: <Iconify icon={'akar-icons:people-group'} />,
    trackerEvent: 'teams-sidenav',
    featureName: 'teams',
  });
  configureNavItems.push({
    id: 'cloudaccounts-sidenav-tab',
    title: 'Cloud Accounts',
    path: '/orgs/:orgId/accounts/:accountId/cloud_accounts',
    icon: <Iconify icon={'material-symbols:cloud-outline'} />,
    trackerEvent: 'cloudaccounts-sidenav',
    featureName: 'cloudaccounts',
  });
  configureNavItems.push({
    id: 'integrations-sidenav-tab',
    title: 'Integrations',
    path: '/orgs/:orgId/accounts/:accountId/integrations',
    icon: <Iconify icon={'bxs:network-chart'} />,
    trackerEvent: 'integrations-sidenav',
  });
  configureNavItems.push({
    id: 'settings-sidenav-tab',
    title: 'Settings',
    path: '/orgs/:orgId/accounts/:accountId/settings',
    icon: (
      <Iconify
        icon="heroicons-outline:cog"
        sx={{ transform: 'rotate(90deg) scaleY(-1)' }}
      />
    ),
    trackerEvent: 'settings-sidenav',
  });
  configureNavItems.push({
    id: 'billings-sidenav-tab',
    title: 'Billing & Subscriptions',
    path: '/orgs/:orgId/subscriptions',
    icon: <Iconify icon="fontisto:wallet" />,
    trackerEvent: 'settings-sidenav',
  });
  //Left Side Navigation Config
  const config: {
    subheader: string;
    items: NavListProps[];
  }[] = [];
  config.push({
    subheader: 'RISK LEDGER',
    items: navConfigItems,
  });

  config.push({
    subheader: 'ARTIFACT REGISTER',
    items: artifactRegisterNavItems,
  });

  config.push({
    subheader: 'UPGRADE COPILOT',
    items: upgradesNavItems,
  });

  config.push({
    subheader: 'CONFIGURE',
    items: configureNavItems,
  });
  config.push({
    subheader: 'EXPLORE',
    items: [
      {
        title: 'Concepts',
        id: 'concepts-sidenav-tab',
        path: '/orgs/:orgId/accounts/:accountId/concepts',
        icon: <Iconify icon={'mdi:lightbulb-variant-outline'} />,
        trackerEvent: 'concepts-sidenav',
      },
      {
        title: 'Security',
        id: 'security-sidenav-tab',
        path: '/orgs/:orgId/accounts/:accountId/security',
        icon: <Iconify icon={'mdi:security-lock-outline'} />,
        trackerEvent: 'security-sidenav',
      },
      {
        title: 'Clouds & Add-ons',
        id: 'clouds-addons-sidenav-tab',
        path: '/orgs/:orgId/accounts/:accountId/explore/supported',
        icon: <Iconify icon={'lucide:cloudy'} />,
        trackerEvent: 'explore-supported',
      },
      {
        title: 'Troubleshooting',
        id: 'troubleshoot-sidenav-tab',
        path: '/orgs/:orgId/accounts/:accountId/troubleshoot-k8s-connector',
        icon: <Iconify icon={'mdi:lightbulb-variant-outline'} />,
        trackerEvent: 'troubleshoot-k8s-connector',
      },
    ],
  });
  return config;
}
