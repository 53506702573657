import { useState } from 'react';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Skeleton,
  Typography,
} from '@mui/material';
// hooks
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
// utils
import cssStyles from '../../../utils/cssStyles';
// config

// components
import Iconify from 'components/Iconify';
import LoadingIcon from 'components/animate/LoadingIcon';
import { ClusterDetailsBreadCrumbsHeader } from 'components/BreadCrumbsHeaders';
import { AtomicTooltip } from 'components/design-system';
import { useLocation } from 'react-router';

export type CLUSTER_TABS_TYPE = {
  key: string;
  id: string;
  title: string;
  trackerEvent: string;
  component: JSX.Element | undefined;
  icon: string;
  skeleton: JSX.Element | undefined;
}[];

type Props = {
  title: string | undefined;
  clusterId: string;
  clusterStatus: string;
  lastScanText: string;
  rescanComplete: boolean | null;
  triggerRescan: () => Promise<void>;
  isRescanButtonDisabled: boolean;
  setCurrentTab: any;
};

const RootStyle = styled(Stack)(({ theme }) => ({
  boxShadow: 'none',
  display: 'flex',
  marginLeft: theme.spacing(2),
  marginTop: theme.spacing(2),
  position: 'relative',
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ---------------------------------------

export default function ClusterDetailsSectionHeader({
  title,
  lastScanText,
  rescanComplete,
  triggerRescan,
  isRescanButtonDisabled,
  clusterId,
  clusterStatus,
  setCurrentTab,
}: Props) {
  const { logEvent } = AnalyticsEventLogger();
  const theme = useTheme();

  return (
    <RootStyle>
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 1,
        }}
      >
        {title ? (
          <ClusterDetailsBreadCrumbsHeader
            clusterName={title}
            clusterId={clusterId}
          />
        ) : (
          <Skeleton width={200} height={35} />
        )}

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {lastScanText ? (
            <Typography
              id="cluster-details-last-scan"
              sx={{ paddingRight: 2, color: theme.palette.grey[600] }}
              variant="body2"
            >
              {lastScanText}
            </Typography>
          ) : (
            <Skeleton width={150} height={20} sx={{ mr: 2 }} />
          )}
          <AtomicTooltip
            tooltipContent={
              clusterStatus.toLowerCase() !== 'active'
                ? 'Rescan is not possible on Deactivated clusters.'
                : rescanComplete === false || !lastScanText
                ? 'Rescanning Cluster'
                : 'Rescan Cluster'
            }
          >
            <IconButton
              disabled={
                clusterStatus.toLowerCase() !== 'active' ||
                isRescanButtonDisabled
              }
              onClick={(event) => {
                if (event?.stopPropagation) {
                  event.stopPropagation();
                  triggerRescan();
                  logEvent('rescan-cluster', {
                    clusterId,
                  });
                }
              }}
              sx={{
                backgroundColor: theme.palette.grey[800],
                marginRight: '8px',
                '&.Mui-disabled': {
                  background: theme.palette.grey[800],
                  opacity: '0.5',
                },
              }}
            >
              {(!lastScanText || rescanComplete === false) &&
              clusterStatus.toLowerCase() === 'active' ? (
                <LoadingIcon animating={true} />
              ) : (
                <Iconify
                  sx={{ color: theme.palette.common.white }}
                  icon={'iconoir:refresh'}
                  width={16}
                  height={16}
                />
              )}
            </IconButton>
          </AtomicTooltip>
        </Box>
      </Grid>
    </RootStyle>
  );
}
