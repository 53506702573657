import { ReactNode, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Link, LinkProps } from '@mui/material';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import Iconify from 'components/Iconify';
import { AtomicTooltip } from 'components/design-system';
import { IconButton } from 'subframe/index';
import { IconName } from '@subframe/core';

// ----------------------------------------------------------------------

interface RouterLinkProps extends LinkProps {
  component?: ReactNode;
  to?: string;
  end?: boolean;
}

const LinkStyle = styled(Link)<RouterLinkProps>(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.48,
    textDecoration: 'none',
  },
}));

// ----------------------------------------------------------------------

interface Props {
  title: string;
  trackEvent: string;
  iconName: IconName;
  href?: string;
  to?: string;
  clickHandler?: () => void;
}

export default function HeaderLink({
  title,
  iconName,
  trackEvent,
  href,
  to,
  clickHandler,
}: Props) {
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const { logEvent } = AnalyticsEventLogger();

  return (
    <LinkStyle
      id={trackEvent}
      href={href}
      target="_blank"
      rel="noopener"
      to={to}
      onClick={() => {
        logEvent(trackEvent);
        if (clickHandler) {
          clickHandler();
        }
      }}
    >
      <AtomicTooltip tooltipContent={title}>
        <div
          onMouseEnter={() => setToolTipOpen(true)}
          onMouseLeave={() => setToolTipOpen(false)}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <IconButton icon={iconName} size="medium" />
        </div>
      </AtomicTooltip>
    </LinkStyle>
  );
}
