// @mui
import { styled } from '@mui/material/styles';
import { AppBar, Box, Stack, Toolbar } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import { useState } from 'react';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import AccountPopover from './AccountPopover';
import HeaderLink from './HeaderLink';

import { AlertDialog } from 'components/dialogs';

import { Tracker } from 'components/tracker';
// import ThemeSwitcher from 'components/ThemeSwitcher';
import useDialog from 'hooks/useDialog';
import NotificationsPopover from './NotificationsPopover';
import { AskK8SExpertsDialog } from 'components/dialogs/AskK8sExpertDialog';
import { useListSubscriptions } from 'api/frontend';
import useUserAccountState from 'hooks/useUserAccountState';
import {
  canRequestPlanExtension,
  getHeaderExpiryInDaysText,
  getMostRelevanSubscription,
  showUpdateNow,
} from 'utils/subscription_plan';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import * as Sentry from '@sentry/react';
import { ProductPlanDemo } from 'components/ProductPlanDemo';
import { Button } from 'subframe/index';
import { RouterLink } from 'components/RouterLink';
import { RequestSubscriptionPlanExtensionCTA } from 'components/RequestSubscriptionPlanExtensionCTA';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
// ----------------------------------------------------------------------

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  borderBottom: `dashed 1px ${theme.palette.divider}`,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const { currentDialog, alertDialogDetails } = useDialog();
  const { logEvent } = AnalyticsEventLogger();
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');
  const [showAskK8sExpertDialog, setShowAskK8sExpertDialog] = useState(false);

  const { account, currentOrganization } = useUserAccountState();

  const { data: subscriptions, error: subscriptionError } =
    useListSubscriptions(
      currentOrganization.slug,
      {},
      {
        request: { headers: { authorization: 'Bearer ' + account?.token } },
      },
    );
  const mainSubscription = getMostRelevanSubscription(
    subscriptions?.subscriptions,
  );

  const navItems = [
    {
      title: 'Give us feedback',
      trackEvent: 'give-us-feedback',
      clickHandler: () => {
        setShowAskK8sExpertDialog(true);
      },
      iconName: 'FeatherMessageSquare',
    },
  ];
  const subscriptionPlanPath = useAccountIdRoute(
    '/orgs/:orgId/subscriptions?view=billing',
  );

  const isDemo = mainSubscription?.plan === 'Demo' && !subscriptionError;
  const trialExpireText = {
    mainText: mainSubscription?.expiry
      ? `${getHeaderExpiryInDaysText(
          mainSubscription?.expiry,
          mainSubscription?.name,
        )} `
      : `You are currently using the ${mainSubscription?.name} Plan.`,
    CTATextUpgradeNow: 'Upgrade Now',
    CTATextRequestExtension: 'Request Extension',
  };

  if (subscriptionError) Sentry.captureException(subscriptionError.message);

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: '100% !important',
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 2, sm: 4 }}>
          {/* Temporarily hiding light/dark theme switch until we fix light mode UX  */}
          {/* <ThemeSwitcher  /> */}

          {showUpdateNow(mainSubscription?.plan) && (
            <div className="flex items-center gap-1 pr-4">
              <span className="text-body text-default-font">
                {trialExpireText.mainText}
              </span>
              {isDemo ? (
                <ProductPlanDemo view={'top-bar'} />
              ) : (
                <>
                  <RouterLink
                    to={subscriptionPlanPath} //TODO: Send a free-trial request in Demo mode
                    onClick={() => {
                      logEvent('header-upgrade-now-click');
                    }}
                  >
                    <Button size="small">{`${trialExpireText.CTATextUpgradeNow}`}</Button>
                  </RouterLink>
                  {mainSubscription &&
                    canRequestPlanExtension(mainSubscription) && (
                      <>
                        <span className="text-body text-default-font">or</span>
                        <RequestSubscriptionPlanExtensionCTA
                          size={'small'}
                          iconName={null}
                          variant="brand-secondary"
                          description={trialExpireText.CTATextRequestExtension}
                          subscription={mainSubscription}
                          clickHandler={() => {
                            logEvent('header-subscription-alert-click', {
                              text: 'Request Extension',
                            });
                          }}
                        />
                      </>
                    )}
                </>
              )}
            </div>
          )}
          <NotificationsPopover />
          {navItems.map((item, idx) => (
            <Tracker key={idx} event={item.trackEvent}>
              <HeaderLink key={idx} {...item} />
            </Tracker>
          ))}

          <AccountPopover />
        </Stack>

        <AskK8SExpertsDialog
          open={showAskK8sExpertDialog}
          handleOpenChange={(status) => {
            setShowAskK8sExpertDialog(status);
          }}
        />
        <AlertDialog
          message={alertDialogDetails.message}
          type={alertDialogDetails.type}
          open={currentDialog === 'alert'}
          handleClose={() => {}}
        />
      </Toolbar>
    </RootStyle>
  );
}
