import { useEffect } from 'react';
import { useEventCallback } from './hooks';

function getIsDocumentHidden() {
  return !document.hidden;
}

export function usePageVisible(callback: (isVisible: boolean) => void) {
  const onVisibilityChange = useEventCallback(() => {
    callback(getIsDocumentHidden());
  }, [callback]);

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange, false);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [onVisibilityChange]);
}
