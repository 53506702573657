import { SyntheticEvent, useState } from 'react';
// @mui
import {
  Autocomplete,
  Box,
  IconButton,
  Popover,
  TextField,
} from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
// utils
// config
import { HEADER } from '../../../config';
// components
import { ClusterDetectedLar, ClusterScannedSignature } from 'api/models';
import Iconify from 'components/Iconify';
import { clusterDetailsTableView } from 'utils/filters';
import ClusterDetailsTabs from './ClusterDetailsTabs';
import FilterForm from './FilterForm';
import { Searchbar } from 'components/design-system/Searchbar';

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};
type ChangeFunction = (
  event: SyntheticEvent<Element, Event>,
  newValue: string,
) => void;

type FormValues = {
  severity: string;
};

type Props = {
  isCollapse?: boolean;
  verticalLayout?: boolean;
  title: string;
  items?: ClusterDetectedLar[] | ClusterScannedSignature[];
  onChange: ChangeFunction;
  type: string;
  handleFilterSubmit: (formValues: FormValues) => void;
  filterClustersType: FormValues;
  clusterId?: string;
  filterSelected?: boolean;
  tableView?: clusterDetailsTableView;
  setTableView: any;
  tabCount: any;
  hideSeverity: boolean;
  setIsLoading: any;
};

const RootStyle = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(4),
  position: 'relative',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_SECTION_HEIGHT,
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
}));

// ----------------------------------------------------------------------

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '24px',
  backgroundColor: theme.palette.grey[900],
  border: `1px solid ${theme.palette.grey[800]}`,
  height: 32,
  alignSelf: 'center',
  padding: 0,
  alignItems: 'center',
  display: 'flex',
  '&:hover': {
    backgroundColor: alpha(theme.palette.grey[400], 0.2),
  },
  '& .MuiOutlinedInput-root': {
    paddingLeft: '36px !important',
    width: '240px',
  },
  '& .MuiInputLabel-root': {
    paddingLeft: '36px',
  },
  marginLeft: 0,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(Autocomplete)(({ theme }) => ({
  color: theme.palette.grey[700],
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    color: theme.palette.grey[400],
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
      width: '25ch',
    },
    borderColor: theme.palette.grey[300],
  },
  '& .MuiOutlinedInput-input': {
    paddingBottom: '12px !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'transparent',
  },
  '& .MuiAutocomplete-input': {
    fontSize: '14px',
    marginTop: '5px',
    paddingLeft: '36px',
  },
}));

export default function ClusterDetailsSearchHeader({
  isCollapse = false,
  verticalLayout = false,
  title,
  onChange,
  items,
  type,
  handleFilterSubmit,
  filterClustersType,
  clusterId,
  filterSelected,
  tableView,
  setTableView,
  tabCount,
  hideSeverity,
  setIsLoading,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const handleOpenClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const theme = useTheme();
  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <ClusterDetailsTabs
        tableView={tableView}
        setTableView={setTableView}
        tabCount={tabCount}
        hideSeverity={hideSeverity}
        title={title}
        clusterId={clusterId}
        setIsLoading={setIsLoading}
      />
      {items?.length ? (
        <Box sx={{ display: 'flex' }}>
          {items?.length ? <Searchbar onChange={onChange} /> : <></>}

          <div
            style={{
              width: '16px',
            }}
          />

          {!hideSeverity && (
            <>
              <IconButton
                onClick={handleOpenClick}
                sx={{
                  height: 50,
                  width: 50,
                }}
                disabled={items?.length < 1}
              >
                <Iconify
                  sx={{
                    color: `${
                      filterSelected ? '#fda92d' : theme.palette.grey[500]
                    }`,
                  }}
                  icon={'icon-park-outline:filter'}
                  width="100"
                  height="100"
                />
              </IconButton>
              <Popover
                id="filter-by-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <FilterForm
                  type={type}
                  filterClustersType={filterClustersType}
                  handleFilterSubmit={handleFilterSubmit}
                  handleClose={handleClose}
                  tableView={tableView}
                />
              </Popover>
            </>
          )}
        </Box>
      ) : (
        <></>
      )}
    </RootStyle>
  );
}
