import { DependencyList, useRef, useEffect, useCallback } from 'react';

// Callback that is not recreated, even if `dependencies` changes.
//
// NOTE: types same as useCallback()
// Taken from https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback
export const useEventCallback = <T extends (...args: any[]) => any>(
  fn: T,
  dependencies: DependencyList,
): T => {
  const ref = useRef<any>(() => {
    console.error(
      'Cannot call an event handler while rendering. See useEventCallback',
    );
  });

  useEffect(() => {
    ref.current = fn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fn, ...dependencies]);

  return useCallback<any>(
    (...args: any[]) => {
      const fn = ref.current;
      return fn(...args);
    },
    [ref],
  );
};
