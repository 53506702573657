// @mui
import { styled, useTheme } from '@mui/material/styles';
import {
  Divider,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import Iconify from 'components/Iconify';
import {
  clearSensitivity,
  DetectedTerm,
  getNeedAttentionTooltip,
  NotDetectedTerm,
} from 'utils/filters';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { AtomicTooltip } from 'components/design-system';

type Props = {
  tableView?: string;
  setTableView: any;
  tabCount: any;
  hideSeverity: boolean;
  title: string;
  clusterId?: string;
  setIsLoading: any;
};
// ----------------------------------------------------------------------

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    '&.MuiToggleButtonGroup-root': {
      disableRipple: true,
      color: theme.palette.grey[300],
      backgroundColor: 'transparent',
      border: 'none',
      fontWeight: 400,
      fontFamily: theme.typography.fontFamily,
      minWidth: '200px',
      height: '66px',
      marginLeft: '12px',
    },
    '&.Mui-selected': {
      fontSize: '2rem',
    },
    '&.Mui-selected, &.Mui-selected:hover': {
      color: 'white',
      backgroundColor: 'transparent',
    },
  }),
);
export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  textAlign: 'left',
  minWidth: '60px',
  color: theme.palette.grey[300],
  fontWeight: '400 !important',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: 'transparent',
    fontWeight: '700 !important',
  },
  '&:hover': {
    '& .hover-change': {},
    backgroundColor: 'transparent',
    color: 'white',
  },
}));

export const StyledDivider = styled(Divider)(() => ({
  width: '3px',
  height: '66px',
}));

export default function ClusterDetailsTabs({
  tableView,
  setTableView,
  tabCount,
  hideSeverity,
  title,
  clusterId,
  setIsLoading,
}: Props) {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string,
  ) => {
    if (nextView !== null) {
      logEvent(`${nextView}-filtered-view`, { clusterId: { clusterId } });
      setIsLoading(true);
      setTableView(nextView);
    }
  };
  const theme = useTheme();

  const { logEvent } = AnalyticsEventLogger();
  return (
    <StyledToggleButtonGroup
      aria-label="text button group"
      value={tableView}
      onChange={handleChange}
      exclusive
      id="table-view-tabs"
    >
      {clearSensitivity(title) !== 'guardrails' && (
        <StyledDivider
          orientation="vertical"
          flexItem
          color="red"
          sx={{ borderColor: 'red' }}
        />
      )}
      {clearSensitivity(title) !== 'guardrails' && (
        <StyledToggleButton
          value="NeedsAttention"
          aria-label="NeedsAttention"
          disableTouchRipple
          disableRipple
          disableFocusRipple
          id="need-attention-table-view"
          theme={theme}
        >
          <Stack>
            <span>
              {' '}
              Need Attention{' '}
              <AtomicTooltip
                tooltipContent={
                  <Typography sx={{ fontSize: '0.75rem' }}>
                    {getNeedAttentionTooltip(title)}
                  </Typography>
                }
              >
                <IconButton
                  sx={{
                    width: 18,
                    height: 18,
                    padding: 0,
                    color: theme.palette.grey[300],
                  }}
                >
                  <Iconify
                    icon="eva:info-fill"
                    sx={{ cursor: 'pointer' }}
                    id="need-attention-info"
                  />
                </IconButton>
              </AtomicTooltip>
            </span>
            <span style={{ fontSize: '20px' }} id="need-attention-count">
              {tabCount.NeedsAttention}
            </span>
          </Stack>
        </StyledToggleButton>
      )}

      <StyledDivider
        orientation="vertical"
        flexItem
        color={theme.palette.grey[300]}
        sx={{ borderColor: `${theme.palette.grey[100]}` }}
      />
      <StyledToggleButton
        value="Detected"
        aria-label="Detected"
        disableTouchRipple
        disableRipple
        disableFocusRipple
        id="detected-table-view"
        theme={theme}
      >
        <Stack>
          {DetectedTerm(title)}
          <span style={{ fontSize: '20px' }} id="detected-count">
            {tabCount.Detected}
          </span>
        </Stack>
      </StyledToggleButton>
      <StyledDivider
        orientation="vertical"
        flexItem
        color={theme.palette.grey[700]}
        sx={{ borderColor: `${theme.palette.grey[700]}` }}
      />
      <StyledToggleButton
        value="Ignored"
        disableTouchRipple
        disableRipple
        disableFocusRipple
        id="ignored-table-view"
        theme={theme}
      >
        <Stack>
          Ignored
          <span style={{ fontSize: '20px' }} id="ignored-count">
            {tabCount.Ignored}
          </span>
        </Stack>
      </StyledToggleButton>

      <StyledDivider
        orientation="vertical"
        flexItem
        color="#007B55"
        sx={{ borderColor: '#007B55' }}
      />
      <StyledToggleButton
        value="UnDetected"
        disableTouchRipple
        disableRipple
        disableFocusRipple
        id="undetected-table-view"
        theme={theme}
      >
        <Stack>
          {NotDetectedTerm(title)}
          <span style={{ fontSize: '20px' }} id="undetected-count">
            {tabCount.UnDetected}
          </span>
        </Stack>
      </StyledToggleButton>
    </StyledToggleButtonGroup>
  );
}
