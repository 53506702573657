import { IconButton } from 'subframe/index';
import styles from './style.module.scss';

interface DrawerProps {
  title: string;
  children: React.ReactNode;

  open: boolean;
  setOpen: (open: boolean) => void;
}

export function Drawer(props: DrawerProps) {
  return (
    <div
      className={styles['drawer']}
      data-state={props.open ? 'open' : 'closed'}
    >
      <div className={styles['pageContents']}>
        <div className={styles['stack']}>
          <div className={styles['stack-f5323de6']}>
            <span className={styles['subheaderText']}>{props.title}</span>
            <IconButton
              icon="FeatherX"
              size="medium"
              onClick={() => props.setOpen(false)}
            />
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
}
