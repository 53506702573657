import * as React from 'react';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Stack,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { pick } from 'lodash';
import { Spacer } from 'components/Spacer';
import Iconify from 'components/Iconify';
import { clusterDetailsTableView } from 'utils/filters';
import { Button } from 'subframe/index';

type FormValues = {
  severity: string;
};

type Props = {
  handleClose: VoidFunction;
  type: string;
  handleFilterSubmit: (formValues: FormValues) => void;
  filterClustersType: FormValues;
  tableView?: clusterDetailsTableView;
};

type FormOptions = {
  severity: {
    value: string;
    label: string;
  }[];
};

type FormSelectProps = {
  title: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  type: string;
  label: string;
  formOptions: FormOptions;
  tableView?: clusterDetailsTableView;
};

const SEVERITY_FORM_CONFIG = [
  {
    title: 'Severity:',
    type: 'severity',
    label: 'Choose an option',
  },
];

const INITIAL_STATE = {
  severity: '',
};

const SelectTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '& .MuiOutlinedInput-input': {
    heigth: 32,
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.common.white,
    fontSize: 14,
  },
}));

const FormSelectItem = ({
  title,
  handleChange,
  value,
  type,
  label,
  formOptions,
  tableView,
}: FormSelectProps) => {
  const optionKey = type as keyof typeof formOptions;
  const theme = useTheme();

  return (
    <FormControl sx={{ padding: theme.spacing(1, 0) }} variant="standard">
      <Typography
        sx={{
          paddingBottom: 1,
        }}
        variant="body2"
      >
        {title}
      </Typography>
      <SelectTextField
        id="filter-by-providers"
        select
        label={label}
        value={value}
        onChange={handleChange}
        size="small"
        sx={{
          width: '320px',
          borderRadius: '8px',
        }}
      >
        {formOptions[optionKey].map((option) => (
          <MenuItem
            sx={{ color: theme.palette.common.white }}
            key={option.value}
            value={option.value}
            disabled={
              tableView === 'NeedsAttention' &&
              (option.value === 'medium' || option.value === 'low')
                ? true
                : undefined
            }
          >
            {option.label}
          </MenuItem>
        ))}
      </SelectTextField>
    </FormControl>
  );
};

export default function FilterForm({
  handleClose,
  type,
  handleFilterSubmit,
  filterClustersType,
  tableView,
}: Props) {
  const theme = useTheme();
  const [formValues, setFormValues] = React.useState({
    ...INITIAL_STATE,
    ...filterClustersType,
  });
  const { logEvent } = AnalyticsEventLogger();
  const formOptions = {
    severity: [
      {
        value: 'critical',
        label: 'Critical',
      },
      {
        value: 'high',
        label: 'High',
      },
      {
        value: 'medium',
        label: 'Medium',
      },
      {
        value: 'low',
        label: 'Low',
      },
      {
        value: 'all',
        label: 'Show All',
      },
    ],
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    const optionKey = type as keyof typeof formValues;

    setFormValues((prev) => {
      return {
        ...prev,
        [optionKey]: event.target.value,
      };
    });
  };

  const formConfig = SEVERITY_FORM_CONFIG;

  const isButtonDisabled =
    JSON.stringify(formValues) === JSON.stringify(INITIAL_STATE);

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1 },
        p: 2,
      }}
      noValidate
      autoComplete="off"
    >
      <Grid
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 2,
        }}
      >
        <Typography variant="h2">Filters</Typography>
        <IconButton
          onClick={handleClose}
          sx={{
            backgroundColor: theme.palette.grey[900],
          }}
        >
          <Iconify
            icon="emojione-monotone:heavy-multiplication-x"
            height={14}
            width={14}
            color={theme.palette.common.white}
          />
        </IconButton>
      </Grid>
      <Stack>
        {formConfig.map((item, idx) => {
          const { title, type, label } = item;
          const optionKey = type as keyof typeof formValues;

          return (
            <FormSelectItem
              key={idx}
              title={title}
              label={label}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(event, type)
              }
              type={type}
              value={formValues[optionKey]}
              formOptions={formOptions}
              tableView={tableView}
            />
          );
        })}
        <Button
          size="large"
          disabled={isButtonDisabled}
          onClick={() => {
            handleFilterSubmit(formValues);
            logEvent(
              `apply-filters-${type.toLowerCase()}`,
              pick(formValues, ['severity']),
            );
            handleClose();
          }}
        >
          Apply Filters
        </Button>
        <Spacer value={4} />
        {!isButtonDisabled && (
          <Link
            onClick={() => {
              setFormValues({ ...INITIAL_STATE, ...filterClustersType });
              handleFilterSubmit({ ...INITIAL_STATE });
              handleClose();
            }}
            underline="none"
            sx={{ cursor: 'pointer', fontSize: '14px' }}
            id="clear-all-filters"
          >
            {' '}
            Clear All
          </Link>
        )}{' '}
      </Stack>
    </Box>
  );
}
