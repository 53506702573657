import { useState, useEffect } from 'react';

// ----------------------------------------------------------------------

export default function useSessionStorage<T extends string | number | boolean>(
  key: string,
  defaultValue: T,
): [T, (newValue: T | ((prevValue: T) => T)) => void] {
  const [value, setValue] = useState<T>(() => {
    const storedValue = sessionStorage.getItem(key);
    if (storedValue === null) {
      sessionStorage.setItem(key, JSON.stringify(defaultValue));
      return defaultValue;
    } else {
      return JSON.parse(storedValue) as T;
    }
  });

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.storageArea === sessionStorage && e.key === key) {
        setValue(e.newValue ? (JSON.parse(e.newValue) as T) : defaultValue);
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [key, defaultValue]);

  const setValueInSessionStorage = (
    newValue: T | ((prevValue: T) => T),
  ): void => {
    if (typeof newValue === 'function') {
      setValue((currentValue: T) => {
        const result = newValue(currentValue);
        sessionStorage.setItem(key, JSON.stringify(result));
        return result;
      });
    } else {
      sessionStorage.setItem(key, JSON.stringify(newValue));
      setValue(newValue);
    }
  };

  return [value, setValueInSessionStorage];
}
