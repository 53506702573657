import { Outlet, Link as RouterLink, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
//components
import Logo from 'components/Logo';
import { Tracker } from 'components/tracker';
import ThemeSwitcher from 'components/ThemeSwitcher';

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  maxWidth: theme.breakpoints.values.lg,
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

interface Props {
  showLoginBtn?: boolean;
  children?: JSX.Element;
}

export default function LogoOnlyLayout({
  showLoginBtn = true,
  children,
}: Props) {
  const theme = useTheme();
  const navigate = useNavigate();

  const isSmallScreenDevice = () => {
    // viewport size cap of 1000px is chosen based on
    // the minimum resolution app can support best
    return window.innerWidth <= 1000;
  };

  const loginRedirect = () => {
    if (isSmallScreenDevice()) {
      navigate('/login?redirect_to=/unsupported-device');
    } else {
      navigate('/login?redirect_to=/');
    }
  };

  return (
    <>
      <HeaderStyle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Logo style={{ width: 160, height: 'initial' }} />
        {showLoginBtn && (
          <Stack direction="row" spacing={2} alignItems="center">
            <Tracker event="login">
              <Button
                id="login-button"
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  color: theme.palette.grey[30],
                  borderColor: theme.palette.grey[30],
                  fontSize: '1rem',
                  height: { md: '3.25rem' },
                  width: { md: '9rem' },
                }}
                onClick={() => {
                  loginRedirect();
                }}
              >
                Log in
              </Button>
            </Tracker>
          </Stack>
        )}
      </HeaderStyle>
      <Outlet />
      {children}
    </>
  );
}
